import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { Box, Col, Container, Row, Typography } from 'db-npm-rdui';
import * as React from 'react';
import styled from 'styled-components';
import { FieldChecker, LabelGrey, LinkAnimatedUnderline, Section } from '../../shared/components';
import ConvertDate, { Languages } from '../../shared/utils/dateParser';
import { richTextStyles } from '../RichText/richTextStyles';
import { IconDownload } from './IconDownload';

const DateDiv = styled.div`
  position: relative;
  display: inline-block;
  line-height: 1;
  color: black;
`;

const InnerDateDiv = styled.div`
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  :not(:empty)::before {
    left: -5px;
  }
  :not(:empty)::after {
    right: -5px;
  }
  :not(:empty)::after,
  ::before {
    position: absolute;
    content: '';

    background: #d8d8d8;
    height: 12px;
    width: 2px;
    transform: skewX(-10deg);
    bottom: 2px;
  }
`;

/* Out of 3 variations proposed we need to select one field. */
function SelectSingleField(fields: any = {}) {
  if (fields['Link'] && fields['Link']?.value?.text && fields['Link']?.value?.href) {
    return (
      <Box textAlign="center" marginTop={6}>
        <LinkAnimatedUnderline field={fields['Link']}>
          <IconDownload /> {fields['Link']?.value?.text}
        </LinkAnimatedUnderline>
      </Box>
    );
  } else if (fields['Date'] && new Date(fields['Date'].value).getFullYear() > 2000) {
    return (
      <Box textAlign="center" marginTop={6}>
        <DateDiv>
          <InnerDateDiv>{ConvertDate(fields['Date']?.value, 'DD MMMM - YYYY', Languages.Denmark)}</InnerDateDiv>
        </DateDiv>
      </Box>
    );
  } else if (fields['Hero Sub Text'] && fields['Hero Sub Text']?.value?.length > 0) {
    return (
      <Box textAlign="center" marginTop={6}>
        <DateDiv>
          <InnerDateDiv>{fields['Hero Sub Text']?.value}</InnerDateDiv>
        </DateDiv>
      </Box>
    );
  }
  return null;
}

export type HeroDefaultProps = {
  fields: any;
};

const HeroDefault: React.FC<HeroDefaultProps> = ({ fields = {} }) => {
  const smallHeader = fields['SmallHeader']?.value === true;
  return (
    <Section componentName="HeroDefault">
      <Container>
        <Row justify="center">
          <Col md={7}>
            <Box paddingTop={{ _: 6, md: 8, lg: 10, xl: 12 }}>
              <FieldChecker type="text" field={fields['Label']}>
                <Box textAlign="center" marginBottom={5}>
                  <LabelGrey field={fields['Label']} />
                </Box>
              </FieldChecker>
              <FieldChecker type="text" field={fields['Headline']}>
                <Box textAlign="center">
                  <Typography type={smallHeader ? 'h2' : 'display'}>
                    <Text field={fields['Headline']} />
                  </Typography>
                </Box>
              </FieldChecker>
              <FieldChecker type="text" field={fields['Description']}>
                <Box textAlign="center" marginTop={5}>
                  <Typography type="manchetBig">
                    <Text field={fields['Description'] ?? {}} />
                  </Typography>
                </Box>
              </FieldChecker>
              <FieldChecker type="text" field={fields['Rich Text Description']}>
                <Box textAlign="center" marginTop={5}>
                  <Typography type="manchetBig">
                    <StyledRichText field={fields['Rich Text Description']} />
                  </Typography>
                </Box>
              </FieldChecker>
              {SelectSingleField(fields)}
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

const StyledRichText = styled(RichText)`
  ${richTextStyles}
`;

export default HeroDefault;
