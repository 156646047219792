import { Button, Color, mediaQuery, Modal, RadioButtonBox, RadioButtonBoxGroup, Typography } from 'db-npm-rdui';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FormFooter, FormLayout, FormRow } from '../../shared/components/Form';
import { useAdobeTracking } from '../../shared/hooks';
import { handleTranslation } from '../../shared/utils/handleTranslation';

type Props = {
  fields: any;
  goToNextPage: () => void;
  page: number;
};

const Page0Choose: React.FC<Props> = ({ fields = {}, goToNextPage, page }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);
  const { onFormInteraction } = useAdobeTracking();
  const callMeFormURL = fields['Call Me Form URL']?.value?.href || '';

  const modalContactHeadLabel = handleTranslation('modal-contact-head-label');
  const modalContactCPRLabel = handleTranslation('modal-contact-content-label');
  const modalFooterButtonLabel = handleTranslation('modal-contact-button-label');
  const modalContactFooterLabel = handleTranslation('modal-contact-footer-label');
  const modalContactPhoneLabel = handleTranslation('modal-contact-footer-phone-label');
  const modalFooterOpenLabel = handleTranslation('modal-contact-footer-open-label');
  const modalFooterTimingLabel = handleTranslation('modal-contact-footer-timing-label');

  /**
   * This function toggles the "showModal" to the opposite value of the current
   */
  const toggleModal = () => setShowModal(!showModal);

  const callmeForm = () => {
    if (!!callMeFormURL && window && window.location) {
      window.location.href = callMeFormURL;
    }
  };

  /**
   * The content for the modal
   */
  const modalContent = (
    <ModalContainer>
      <Typography type="h3">{modalContactHeadLabel}</Typography>
      <Typography type="manchetBig" mt={30} mb={30}>
        {modalContactCPRLabel}
      </Typography>
      <ButtonLink>
        <Button onClick={callmeForm}>{modalFooterButtonLabel}</Button>
      </ButtonLink>
      <Typography type="image" mt={30}>
        {modalContactFooterLabel} <ModalTextRed>{modalContactPhoneLabel}</ModalTextRed>
      </Typography>
      <Typography type="image" mt={10}>
        <ModalTextGreen>{modalFooterOpenLabel}</ModalTextGreen>
        {' ' /* Space between green text and the rest */}
        {modalFooterTimingLabel}
      </Typography>
    </ModalContainer>
  );

  return (
    <Formik
      initialValues={{
        hasCpr: 'yes',
      }}
      onSubmit={(values) => {
        if (values.hasCpr === 'yes') {
          goToNextPage();
        } else {
          toggleModal();
        }
      }}
    >
      {({ values, handleChange }) => {
        const trackingChangeHandler = (e) => {
          onFormInteraction(e, handleChange);
        };

        return (
          <Form noValidate>
            <FormLayout headline={fields['Headline']?.value} description={fields['Choose CPR Description']?.value}>
              <TextHeader type="h6" as="h3" mb={30}>
                {fields['Choose CPR Title']?.value}
              </TextHeader>
              <FormRow>
                <RadioButtonWrapper>
                  <RadioButtonBox
                    label={t('form-input-yes-label')}
                    id="cpr_yes"
                    name="hasCpr"
                    value="yes"
                    variant="secondary"
                    checked={values.hasCpr === 'yes'}
                    onChange={trackingChangeHandler}
                  />
                </RadioButtonWrapper>
              </FormRow>
              <FormRow>
                <RadioButtonWrapper>
                  <RadioButtonBox
                    label={t('form-input-no-label')}
                    id="cpr_no"
                    name="hasCpr"
                    value="no"
                    variant="secondary"
                    checked={values.hasCpr === 'no'}
                    onChange={trackingChangeHandler}
                  />
                </RadioButtonWrapper>
              </FormRow>

              <Modal open={showModal} handleClose={toggleModal} ariaCloseLabel={'Close modal'} overlayColor="#1f2532">
                {modalContent}
              </Modal>
            </FormLayout>
            <FormFooter
              step1Label={t('CPR-footer-step1-title')}
              step2Label={t('CPR-footer-step2-title')}
              step3Label={t('CPR-footer-step3-title')}
              trackingChangeHandler={trackingChangeHandler}
              page={page}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default Page0Choose;

const ModalContainer = styled.div`
  padding: 50px 0;
  text-align: center;

  ${mediaQuery.md} {
    width: 664px;
    padding: 80px 0;
  }

  ${mediaQuery.xl} {
    padding: 120px 0 80px 0;
  }
`;

const ButtonLink = styled.a`
  text-decoration: none;
`;

const ModalTextGreen = styled.span`
  color: #008523;
`;

const ModalTextRed = styled.span`
  color: ${Color.rdRed};
  text-decoration: underline;
`;

const RadioButtonWrapper = styled(RadioButtonBoxGroup)`
  label {
    margin-top: 0;
  }
`;

const TextHeader = styled(Typography)`
  margin-bottom: 30px;
`;
