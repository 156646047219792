import { Color, ErrorMessage, Icons, InputField, Select, Tooltip, Typography } from 'db-npm-rdui';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FormFooter, FormLayout, FormRow } from '../../shared/components/Form';
import { getSchema } from './form-schema';

type Props = {
  fields: any;
  formData: any;
  goToNextPage: () => void;
  goToPrevPage: () => void;
  setFormData: (data: Object) => void;
  setSelectedMailBox: (data: string) => void;
  selectedTopic: string;
  setTopic: (data: string) => void;
  mailToBoxList: any;
  setMailSubject: (data: string) => void;
  page: number;
};

const TOPIC_OPTIONS = [
  {
    label: 'Vælg emne',
    value: '',
  },
  {
    label: 'Legitimation',
    value: 'Legitimation',
  },
  {
    label: 'Udarbejdelse af lånetilbud',
    value: 'Udarbejdelse af lånetilbud',
  },
  {
    label: 'Opfyldelse af forbehold',
    value: 'Opfyldelse af forbehold',
  },
  {
    label: 'Sletning af pantebrev',
    value: 'Sletning af pantebrev',
  },
  {
    label: 'Opsigelse / indfrielse',
    value: 'Opsigelse / indfrielse',
  },
  {
    label: 'Henvendelser til inkassoafdeling',
    value: 'Henvendelser til inkassoafdeling',
  },
  {
    label: 'Andet',
    value: 'Andet',
  },
];

const topicList = [
  'Legitimation',
  'Udarbejdelse af lånetilbud',
  'Opfyldelse af forbehold',
  'Sletning af pantebrev',
  'Opsigelse / indfrielse',
  'Henvendelser til inkassoafdeling',
  'Andet',
];

const CVRForm: React.FC<Props> = ({
  fields = {},
  formData,
  goToNextPage,
  goToPrevPage,
  setFormData,
  setSelectedMailBox,
  mailToBoxList,
  setMailSubject,
  selectedTopic,
  setTopic,
  page,
}) => {
  const { t } = useTranslation();
  const schema = getSchema(t);
  const mailTopicList = mailToBoxList.reduce(function (result, field, index) {
    result[topicList[index]] = field;
    return result;
  }, {});

  const cvr: string = formData?.cvr || '';
  const companyName: string = formData?.companyName || '';
  const contactPerson: string = formData?.contactPerson || '';
  const email: string = formData?.email || '';
  const phone: string = formData?.phone || '';
  const casenumber: string = formData?.casenumber || '';
  const adviser: string = formData?.adviser || '';

  return (
    <Formik
      initialValues={{
        topic: selectedTopic,
        cvr,
        companyName,
        contactPerson,
        email,
        phone,
        casenumber,
        adviser,
      }}
      validationSchema={schema}
      onSubmit={(values) => {
        goToNextPage();
        if (values.casenumber.trim() === '') {
          setMailSubject(values.topic + ' - Intet pantnr - ' + values.contactPerson);
        } else {
          setMailSubject(values.topic + ' - ' + values.casenumber + ' - ' + values.contactPerson);
        }
        setFormData(values);
      }}
    >
      {({ values, errors, touched, handleChange, setFieldValue }) => {
        const setMailbox = (e) => {
          if (e.currentTarget.value) {
            setTopic(e.currentTarget.value);
            setFieldValue('topic', e.currentTarget.value);
            setSelectedMailBox(mailTopicList[e.currentTarget.value]);
          }
        };

        const setPreviousPage = () => {
          goToPrevPage();
          setFormData(values);
        };

        return (
          <Form noValidate>
            <FormLayout headline={fields['Headline']?.value} description={fields['Choose CVR Description']?.value}>
              <FormRow>
                <LabelHeader type="label" as="label" htmlFor="topic">
                  {fields['Form Title']?.value}
                </LabelHeader>
                <WhiteBg>
                  <Select
                    name="topic"
                    id="topic"
                    onChange={setMailbox}
                    options={TOPIC_OPTIONS}
                    hasBorder
                    value={values.topic}
                    isBold
                  />
                </WhiteBg>
                {touched.topic && errors.topic && <ErrorDiv>{errors.topic}</ErrorDiv>}
              </FormRow>

              <FormRow>
                <InputField
                  name="cvr"
                  id="cvr"
                  label={t('form-input-cvr-label')}
                  layout="white"
                  variant="numeric"
                  value={values.cvr}
                  onChange={handleChange}
                  errorMessage={touched.cvr && errors.cvr ? errors.cvr : ''}
                  data-label={t('form-input-cvr-label')}
                  required
                />
              </FormRow>

              <FormRow>
                <InputField
                  name="companyName"
                  id="companyName"
                  label={t('form-input-company-name-label')}
                  layout="white"
                  value={values.companyName}
                  onChange={handleChange}
                  errorMessage={touched.companyName && errors.companyName ? errors.companyName : ''}
                  data-label={t('form-input-company-name-label')}
                  required
                />
              </FormRow>

              <FormRow>
                <SectionTitle>{fields['Section Title']?.value}</SectionTitle>
              </FormRow>

              <FormRow>
                <InputField
                  name="contactPerson"
                  id="contactPerson"
                  label={t('form-input-contact-person-label')}
                  layout="white"
                  value={values.contactPerson}
                  onChange={handleChange}
                  errorMessage={touched.contactPerson && errors.contactPerson ? errors.contactPerson : ''}
                  data-label={t('form-input-contact-person-label')}
                  required
                />
              </FormRow>

              <FormRow>
                <InputField
                  name="email"
                  id="email"
                  label={t('form-input-email-label')}
                  layout="white"
                  value={values.email}
                  onChange={handleChange}
                  errorMessage={touched.email && errors.email ? errors.email : ''}
                  data-label={t('form-input-email-label')}
                  required
                />
              </FormRow>

              <FormRow>
                <InputField
                  name="phone"
                  id="phone"
                  label={t('form-input-phone-label')}
                  layout="white"
                  value={values.phone}
                  onChange={handleChange}
                  errorMessage={touched.phone && errors.phone ? errors.phone : ''}
                  data-label={t('form-input-phone-label')}
                />
              </FormRow>

              <Container>
                <InputField
                  name="casenumber"
                  id="casenumber"
                  label={t('form-input-casenumber-label')}
                  layout="white"
                  value={values.casenumber}
                  onChange={handleChange}
                  errorMessage={touched.casenumber && errors.casenumber ? errors.casenumber : ''}
                  data-label={t('form-input-casenumber-label')}
                />
                <TooltipWrapper>
                  <Tooltip
                    element={<Icons.Question />}
                    heading={t('CVR-form-casenumber-tooltip-header')}
                    content={t('CVR-form-casenumber-tooltip-text')}
                  />
                </TooltipWrapper>
              </Container>

              <Container>
                <InputField
                  name="adviser"
                  id="adviser"
                  label={t('form-input-adviser-label')}
                  layout="white"
                  value={values.adviser}
                  onChange={handleChange}
                  errorMessage={touched.adviser && errors.adviser ? errors.adviser : ''}
                />
                <TooltipWrapper>
                  <Tooltip
                    element={<Icons.Question />}
                    heading={t('CVR-form-adviser-tooltip-header')}
                    content={t('CVR-form-adviser-tooltip-text')}
                  />
                </TooltipWrapper>
              </Container>
            </FormLayout>
            <FormFooter
              step1Label={t('CVR-footer-step1-title')}
              step2Label={t('CVR-footer-step2-title')}
              step3Label={t('CVR-footer-step3-title')}
              setPreviousPage={setPreviousPage}
              page={page}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default CVRForm;

const WhiteBg = styled.div`
  background: #fff;
`;

const Container = styled(FormRow)`
  position: relative;
`;

const ErrorDiv = styled(ErrorMessage)`
  margin-top: 4px;
  margin-left: 20px;
`;

const TooltipWrapper = styled.span`
  position: absolute;
  top: 20px;
  right: 15px;
  svg {
    width: 16px;
    height: 16px;
  }
  [data-popper-placement^='bottom'] div {
    border-color: transparent transparent ${Color.red40};
  }
`;

const SectionTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #595959;
  -webkit-letter-spacing: 2px;
  -moz-letter-spacing: 2px;
  -ms-letter-spacing: 2px;
  letter-spacing: 2px;
  margin-top: 32px;
`;

const LabelHeader = styled(Typography)`
  display: block;
  margin-bottom: 10px;
`;
